import store from '@/store'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAuth() {
  const toast = useToast()
  
  const login = async (user) => {
    try {
      const response = await axios.post('/api/userLogin', { ...user })
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error login.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const fetchUser = async (id) => {
    try {
      const response = await axios.get('/api/userById', { params: { id }})
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const setPassword = async ({ id, password }) => {
    try {
      const response = await axios.post('/api/userSetPassword', { id, password })
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error set password',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const fetchInfo = async (email) => {
    try {
      const response = await axios.get('/api/userInfo', { params: { email }})
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch user info',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const forgotPassword = async ({ id, email }) => {
    try {
      const response = await axios.post('/api/mailSetPassword', { email, link: `https://akara-cms-frontend-oyfta6dtla-as.a.run.app/reset-password?id=${id}` })
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error forgot password',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    login,
    fetchUser,
    setPassword,
    fetchInfo,
    forgotPassword,
  }
}
