import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    login(_, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/userLogin', { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/userById', { params: { id }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInfo(_, email) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/userInfo', { params: { email }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPassword(_, { id, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/userSetPassword', { id, password })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    forgotPassword(_, { id, email }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/mailSetPassword', { email, link: `https://akara-cms-frontend-oyfta6dtla-as.a.run.app/reset-password?id=${id}` })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
